import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine',
  standalone: true
})
export class NewLinePipe implements PipeTransform {

  transform(value: any, separator: string, glue: string): string {
    value = String(value || '');

    const result = value.split(separator).join(glue);
    return result;
  }

}
