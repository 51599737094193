<div class="table-wrapper"
  [class.minitable]="minitable">
  <cdk-virtual-scroll-viewport tvsItemSize="57"
    headerHeight="57"
    style="height: 70rem;">

    <table class="admin-table"
      mat-table
      matSort
      [dataSource]="dataSource">
      @for (column of columns; track column.title) {
      <!-- Text -->
      @if (column.type === ColumnType.Text) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          class="text-header"
          *matHeaderCellDef>
          {{ column.title}}
          <mat-icon class="icon-up"
            (click)="onSortString(column.def, true)">
            arrow_drop_up
          </mat-icon>
          <mat-icon class="icon-down"
            (click)="onSortString(column.def, false)">
            arrow_drop_down
          </mat-icon>
        </th>
        <td mat-cell
          class="text-column"
          *matCellDef="let element">
          {{ element[column.def] | newLine : ',' : '\n' }}
        </td>
      </ng-container>
      }
      <!-- Long Text -->
      @if (column.type === ColumnType.LongText) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          [ngClass]="column.type"
          [title]="element[column.def]"
          *matCellDef="let element">
          {{ element[column.def] }}
        </td>
      </ng-container>
      }
      <!-- Break Text -->
      @if (column.type === ColumnType.BreakText) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          (click)="cellClickEmitter.next(element[column.def])"
          [ngClass]="column.type"
          *matCellDef="let element">
          {{ element[column.def] }}
        </td>
      </ng-container>
      }
      <!-- HTML -->
      @if (column.type === ColumnType.Html) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          class="inner-html"
          *matCellDef="let element">
          <div class="html"
            [innerHtml]="element[column.def]">
          </div>
        </td>
      </ng-container>
      }
      <!-- Number -->
      @if (column.type === ColumnType.Number) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          class="number"
          *matHeaderCellDef>
          {{ column.title }} <mat-icon class="icon-up"
            (click)="onSortNumber(column.def, true)">
            arrow_drop_up
          </mat-icon>
          <mat-icon class="icon-down"
            (click)="onSortNumber(column.def, false)">
            arrow_drop_down
          </mat-icon>
        </th>
        <td mat-cell
          class="number"
          *matCellDef="let element">
          {{ Math.round(element[column.def]) | number: '1.0-0' }}
        </td>
      </ng-container>
      }
      <!-- Instagram -->
      @if (column.type === ColumnType.Instagram) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          class="instagram"
          *matHeaderCellDef>
          <span class="header-table">
            {{ column.title }}
            <mat-icon class="sort-icon"
              (click)="onSortByParam('created')">
              schedule
            </mat-icon>
            <mat-icon class="sort-icon"
              (click)="onSortByParam('views')">
              visibility
            </mat-icon>
            <mat-icon class="sort-icon"
              (click)="onSortByParam('reach')">
              person
            </mat-icon>
            <mat-icon class="sort-icon blue"
              (click)="onSortByParam('likes')">
              thumb_up
            </mat-icon>
            <mat-icon class="sort-icon red"
              (click)="onSortByParams('likes', 'reach')">
              thumb_up
            </mat-icon>
            <mat-icon class="sort-icon blue"
              (click)="onSortByParam('saves')">
              star
            </mat-icon>
            <mat-icon class="sort-icon red"
              (click)="onSortByParams('saves', 'reach')">
              star
            </mat-icon>
            <mat-icon class="sort-icon blue"
              (click)="onSortByParam('shares')">
              share
            </mat-icon>
            <mat-icon class="sort-icon red"
              (click)="onSortByParams('shares', 'reach')">
              share
            </mat-icon>
          </span>
        </th>
        <td mat-cell
          class="instagram"
          *matCellDef="let element">
          <div class="shorter">
            <mat-icon class="table-icon">visibility</mat-icon>
            <span>{{ element.views | number: '1.0-0' }}</span>
          </div>
          <div class="shorter">
            <mat-icon class="table-icon">person</mat-icon>
            <span>{{ element.reach | number: '1.0-0' }}</span>
          </div>
          <div>
            <mat-icon class="table-icon">thumb_up</mat-icon>
            <span>{{ element.likes | number: '1.0-0' }}</span>
            <span>{{element.likes / element.reach | percent:'1.1-1' }}</span>
          </div>
          <div>
            <mat-icon class="table-icon">star</mat-icon>
            <span>{{ element.saves | number: '1.0-0' }}</span>
            <span>{{element.saves / element.reach | percent:'1.1-1' }}</span>
          </div>
          <div>
            <mat-icon class="table-icon">share</mat-icon>
            <span>{{ element.shares | number: '1.0-0' }}</span>
            <span>{{element.shares / element.reach | percent:'1.1-1' }}</span>
          </div>
        </td>
      </ng-container>
      }
      <!-- Color -->
      @if (column.type === ColumnType.Color) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          *matCellDef="let element">
          <div class="color-column"
            [style.background]="element[column.def]">
            {{ element[column.def] }}
          </div>
        </td>
      </ng-container>
      }
      <!-- Boolean -->
      @if (column.type === ColumnType.Boolean) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          class="boolean"
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          class="boolean"
          *matCellDef="let element">
          @if (element[column.def]) {
          <mat-icon class="color-good">check</mat-icon>
          }
          @if (!element[column.def]) {
          <mat-icon class="color-bad">clear</mat-icon>
          }
        </td>
      </ng-container>
      }
      <!-- Checkbox -->
      @if (column.type === ColumnType.Check) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          *matCellDef="let element">
          <mat-checkbox [checked]="element[column.def]"
            (change)="checkBoxEmitter.next(element)"></mat-checkbox>
        </td>
      </ng-container>
      }
      <!-- Date -->
      @if (column.type === ColumnType.Date) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          *matCellDef="let element">
          <span [class.red]="olderThan2Months(element, column)"
            [class.yellow]="olderThan2Weeks(element, column)">
            {{ element[column.def] | date : "d. M. YYYY." }}
            <br>
            <br>
            {{ element[column.def] | date : "H:mm" }}
          </span>
        </td>
      </ng-container>
      }
      <!-- List -->
      @if (column.type === ColumnType.List) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          *matCellDef="let element">
          <div class="list-items">
            {{ handleList(column, element[column.def]) }}
          </div>
        </td>
      </ng-container>
      }
      <!-- Link -->
      @if (column.type === ColumnType.Link) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          *matCellDef="let element">
          <a [href]="element[column.def]"
            target="_blank"
            rel="noopener noreferrer">
            {{ element[column.def] }}</a>
        </td>
      </ng-container>
      }
      <!-- Embedded -->
      @if (column.type === ColumnType.Embedded) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          *matCellDef="let element">
          <a [href]="element[column.def]"
            target="_blank"
            (click)="onLink($event, element)"
            rel="noopener noreferrer">
            <mat-icon>link</mat-icon></a>
        </td>
      </ng-container>
      }
      <!-- Image -->
      @if (column.type === ColumnType.Image) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          class="image"
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          class="image"
          *matCellDef="let element">
          <ng-container>
            <img class="table-thumb"
              loading="lazy"
              [src]="element[column.def]"
              appDefaultLogo
              [alt]="element[column.def]" />
          </ng-container>
        </td>
      </ng-container>
      }
      <!-- Actions -->
      @if (column.type === ColumnType.Actions) {
      <ng-container [matColumnDef]="column.def">
        <th mat-header-cell
          *matHeaderCellDef>
          {{ column.title }}
        </th>
        <td mat-cell
          *matCellDef="let element">
          @if (extraButton) {
          <button mat-stroked-button
            (click)="extraButtonEmitter.next(element)">
            <mat-icon class="bio">face</mat-icon>
          </button>
          }
          <button mat-stroked-button
            (click)="editEmitter.next(element)"
            (contextmenu)="rightClickEmitter.next(element)">
            <mat-icon class="edit">construction</mat-icon>
          </button>
          <button mat-stroked-button
            (click)="deleteEmitter.next(element.id)">
            <mat-icon class="delete">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      }
      }

      <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row
        *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>