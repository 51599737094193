import { TableVirtualScrollDataSource, TableVirtualScrollModule } from 'ng-table-virtual-scroll';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { NewLinePipe } from '@app/pipes/new-line.pipe';
import { Instagram } from '@app/services/instagram.service';
import { DefaultLogoDirective } from '@directives/default-logo.directive';
import { UtilsService } from '@services/utils.service';

export interface TableColumn {
  def: string;
  title: string;
  type: ColumnType;
  list?: any[];
}

export enum ColumnType {
  Link = 'LINK',
  Embedded = 'EMBEDDED',
  List = 'LIST',
  Html = 'HTML',
  Text = 'TEXT',
  BreakText = 'BREAKTEXT',
  LongText = 'LONGTEXT',
  Date = 'DATE',
  Number = 'NUMBER',
  Boolean = 'BOOLEAN',
  Color = 'COLOR',
  Check = 'CHECK',
  Image = 'IMAGE',
  Timings = 'TIMINGS',
  Instagram = 'INSTAGRAM',
  Actions = 'ACTIONS',
}

@Component({
  selector: 'app-table',
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonModule,
    ScrollingModule,
    TableVirtualScrollModule,
    NewLinePipe,
    DefaultLogoDirective,
    PercentPipe,
  ],
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent {
  @Input() title = 'Tabela';
  @Input() extraButton = false;
  @Input({ required: true }) displayedColumns: string[] = [];
  @Input({ required: true }) items: any[] | null = [];
  @Input({ required: true }) columns: TableColumn[] = [];
  @Input() minitable: boolean = false

  @Output() public editEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public rightClickEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public extraButtonEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public deleteEmitter: EventEmitter<string> = new EventEmitter();
  @Output() public checkBoxEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public cellClickEmitter: EventEmitter<string> = new EventEmitter();

  public ColumnType = ColumnType;
  public dataSource = new TableVirtualScrollDataSource();
  public Math = Math;

  ngOnInit() {
    UtilsService.scrollTop();
    this.dataSource = new TableVirtualScrollDataSource(this.items as any[]);
  }

  onSortString(columnDef: any, up: boolean) {
    if (!this.items) {
      return;
    }

    this.items.sort((a: any, b: any) => {
      const value1 = a[columnDef] || '';
      const value2 = b[columnDef] || '';
      if (up) {
        if (value1 && !value2) {
          return -1;
        }
        if (value2 && !value1) {
          return 1;
        }
        return value1.localeCompare(value2);
      }

      return value2.localeCompare(value1);
    });

    this.dataSource = new TableVirtualScrollDataSource(this.items as any[]);
  }
  onSortNumber(columnDef: any, up: boolean) {
    if (!this.items) {
      return;
    }

    this.items.sort((a: any, b: any) => {
      const value1 = a[columnDef] || null;
      const value2 = b[columnDef] || null;
      if (up) {
        if (value1 && !value2) {
          return -1;
        }
        if (value2 && !value1) {
          return 1;
        }
        return value1 - value2;
      }

      return value2 - value1;
    });

    this.dataSource = new TableVirtualScrollDataSource(this.items as any[]);
  }

  onSortByParam(param1: any) {
    if (!this.items) {
      return;
    }

    this.items.sort((a: any, b: any) => {
      const value1 = a[param1] || 0;
      const value2 = b[param1] || 0;
      return value2 - value1;
    });

    this.dataSource = new TableVirtualScrollDataSource(this.items as any[]);
  }

  onSortByParams(param1: any, param2: any) {
    if (!this.items) {
      return;
    }

    this.items.sort((a: any, b: any) => {
      const value1 = (a[param1] || 0) / (a[param2] || 1);
      const value2 = (b[param1] || 0) / (b[param2] || 1);
      return value2 - value1;
    });

    this.dataSource = new TableVirtualScrollDataSource(this.items as any[]);
  }

  handleList(column: any, ids: string[] = []): string {
    if (column.list.length) {
      const displayList = column.list
        .filter((e: any) => ids.includes(e.id))
        .map((e: any) => e.name || e.id);
      return displayList.join(', ');
    }
    return '-';
  }


  onLink(event: any, post: Instagram) {
    event.stopPropagation();
    event.preventDefault();
    const dropPrefix = post.embeddedCode!.split('https')[1];
    const final = 'https' + dropPrefix!.split('?utm_source')[0];
    window.open(final!, '_blank');
  }

  olderThan2Months(element: any, column: any) {
    const todayTimestampMs = Date.now();
    const day = 86_400_000;
    const diff = todayTimestampMs - element[column.def];
    return diff >= 60 * day;
  }

  olderThan2Weeks(element: any, column: any) {
    const todayTimestampMs = Date.now();
    const day = 86_400_000;
    const diff = todayTimestampMs - element[column.def];
    return diff > 14 * day && diff < 60 * day;
  }

}
