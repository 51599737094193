@if (data.items && data.items[currentIndex]; as item) {
<div class="sheet-wrapper">

  <div class="top-block">
    <mat-icon class="close-x"
      (click)="onClose()">close</mat-icon>
    <h6 class="card-title"
      [class.red]="!!item.age">{{item.name || item.age | translit}}</h6>
    @if (item.title) {
    <p class="card-title">{{item.title | translit}}</p>
    }
    @if (item.place) {
    <p class="card-subtitle"
      (click)="onPlace(item.place)">{{item.place | translit}}</p>
    }
  </div>

  @if (item.externalLink) {
  <a class="item-link gagalin"
    [href]="item.externalLink"
    target="_blank">
    <mat-icon>link</mat-icon>
    {{'Званичан веб сајт' | translit}}
  </a>
  }

  <div class="sheet-content pan-vertical"
    (swiperight)="onPreviousIndex()"
    (swipeleft)="onNextIndex()">
    <!-- Image -->
    @if (item.imagePath) {
    <img class="sheet-image"
      [src]="item.imagePath"
      appDefaultLogo
      [alt]="item.name | translit">
    }
    <!-- Description -->
    @if ((item?.innerHtml || item?.description); as text) {
    <div class="text-wrapper sheet-inner-html">
      <span [innerHtml]="text | translit"></span>
    </div>
    }
  </div>
  @if (item.wikipedia) {
  <a class="item-link"
    [href]="item.wikipedia"
    target="_blank">
    <mat-icon>link</mat-icon>
    {{'Линк ка википедији' | translit}}
  </a>
  }
  @if (item.imdb) {
  <a class="item-link"
    [href]="item.imdb"
    target="_blank">
    <mat-icon>link</mat-icon>
    {{'Линк ка ИМДБ' | translit}}
  </a>
  }
  @if (item.wikipedia || item.imdb) {
  <br>
  }
  <!-- Navigate buttons -->
  <div class="navigate-buttons">
    <button mat-stroked-button
      class="previous-button"
      [disabled]="currentIndex === 0"
      (click)="onPreviousIndex()">
      {{'Претходно' | translit}}
      <mat-icon>
        arrow_back_ios</mat-icon>
    </button>
    <button mat-stroked-button
      class="next-button"
      [disabled]="data && data.items && data.items.length && currentIndex === data.items.length - 1"
      (click)="onNextIndex()">
      {{'Следеће' | translit}}
      <mat-icon>
        arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>
} @else if(data.item) {

<div class="sheet-wrapper">
  <div class="pre-text">{{data.item}}</div>
</div>
} @else if(data.table) {

<div class="mini-table">
  <app-table [columns]="data.table.columns"
    [minitable]="true"
    [displayedColumns]="data.table.displayedColumns"
    [items]="data.table.items" />
</div>
}

@if (template) {
<data class="sheet-wrapper template-wrapper">
  <ng-container *ngTemplateOutlet="template" />
</data>
}